.left-image-container {
  position: relative;
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.left-image-container .left {
  position: relative;
  width: 42%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.left-image-container .left .title {
  position: relative;
  width: 100%;
  height: 30%;
  font-size: 2.5em;
  font-weight: 600;
  color: var(--border-color);
  text-align: center;
}

.left-image-container .left .content {
  position: relative;
  width: 100%;
  height: 60%;
  font-size: 1.2em;
  color: var(--word-color);
  text-align: center;
}

.left-image-container .right {
  position: relative;
  width: 40%;
  height: 100%;
  padding-left: 5%;
}

.left-image-container .right img {
  position: relative;
  width: 100%;
  height: 100%;
}

@media all and (max-width: 1000px) {
  .left-image-container {
    flex-direction: column-reverse;
  }
  .left-image-container .left {
    width: 90%;
    height: 45%;
  }
  .left-image-container .left .title {
    font-size: 1.5em;
    height: 20%;
  }
  .left-image-container .left .content {
    font-size: 1em;
    height: 70%;
  }
  .left-image-container .right {
    width: 85%;
    height: 45%;
    padding-left: 0px;
  }
}
