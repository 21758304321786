.HamburgerMenuContainer {
  display: flex;
  padding: 2em;
}

.HamburgerMenuContainer .HamburgerIcon {
  cursor: pointer;
  z-index: 99; /*最高的一個元素*/
  transition: all 250ms ease-in-out;
}

.HamburgerMenuContainer .MenuContainer {
  min-width: 200px;
  width: 100%;
  max-width: 20%;
  height: 100%;
  background-color: var(--color-transparent);
  box-shadow: -2px 0 2px rgba(15, 15, 15, 0.3);
  z-index: 90;
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(4em);
  user-select: none;
  padding: 1em 2.5em;
}
