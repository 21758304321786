.landing-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.landing-container .bg {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: -1;
}

.landing-container .bg img {
  position: relative;
  width: 100%;
  height: 100%;
}

/* Section 1 */

.landing-container .section-1 {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #00000069;
  justify-content: center;
  align-items: center;
}

.landing-container .section-1 .content {
  position: relative;
  width: 75%;
  height: 25%;
  margin-top: 5%;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  font-size: 4em;
  font-weight: 1000;
}
.landing-container .section-1 .logo-container {
  position: relative;
  width: 10%;
  height: auto;
  justify-content: center;
}

.landing-container .section-1 .explore-icon {
  position: relative;
  width: 5%;
  height: auto;
  justify-content: center;
  padding-top: 5%;
}

.landing-container .section-1 .explore-icon path {
  stroke: white;
  stroke-width: 8px;
  stroke-linecap: round;
  stroke-linejoin: round;
}

/* Section 1 */
.landing-container .section-2 {
  position: relative;
  width: 100%;
  height: 125vh;
  display: flex;
  flex-direction: column;
  background-color: var(--page-color);
  justify-content: center;
  align-items: center;
}

.landing-container .section-2 .title {
  position: relative;
  height: 15%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.landing-container .section-2 .title p {
  position: relative;
  border-bottom: solid 0.07em var(--border-color);
  font-size: 4em;
  color: var(--word-color);
  font-weight: 650;
}

.landing-container .section-2 .content {
  position: relative;
  width: 60%;
  height: 30%;
  text-align: center;
  color: var(--word-color);
  font-size: 1.4em;
}

.landing-container .section-3 {
  position: relative;
  width: 100%;
  height: 75vh;
}

.landing-container .section-4 {
  position: relative;
  width: 100%;
  height: 130vh;
  background-color: var(--page-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landing-container .section-4 .title {
  position: relative;
  width: 100%;
  height: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.landing-container .section-4 .title .large {
  position: relative;
  width: 20%;
  height: 20%;
  border-bottom: solid 0.07em var(--border-color);
  font-size: 4em;
  color: var(--word-color);
  font-weight: 650;
}

.landing-container .section-4 .title .content {
  position: relative;
  width: 60%;
  height: 30%;
  margin-top: 10%;
  color: var(--word-color);
  font-size: 1.4em;
}

.landing-container .section-5 {
  position: relative;
  width: 100%;
  height: 75vh;
}

.landing-container .section-6 {
  position: relative;
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  background-color: var(--color-dark-transparent);
  justify-content: center;
  align-items: center;
}

@media all and (max-width: 1000px) {
  .landing-container .section-1 .content {
    font-size: 2.5em;
    width: 90%;
  }
  .landing-container .section-1 .logo-container {
    width: 40%;
  }
  .landing-container .section-1 .logo-container {
    width: 40%;
  }
  .landing-container .section-2 {
    height: 300vh;
  }

  .landing-container .section-2 .title {
    height: 10%;
  }
  .landing-container .section-2 .title p {
    font-size: 2.5em;
  }
  .landing-container .section-2 .content {
    height: 15%;
    font-size: 1em;
  }
  .landing-container .section-3 {
    height: 150vh;
  }
  .landing-container .section-4 {
    height: 180vh;
  }
  .landing-container .section-4 .title .large {
    width: 60%;
    height: 15%;
    font-size: 2.5em;
  }
  .landing-container .section-4 .title .content {
    height: 15%;
    font-size: 1em;
  }
  .landing-container .section-5 {
    height: 150vh;
  }
}
