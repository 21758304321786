.c-container {
  position: relative;
  width: 50%;
  height: 100%;
  background-color: #ffffff;
  overflow: hidden;
}

.c-container .carousel {
  width: 100%;
  height: 100%;
}

.carousel .thumb img {
  width: 100% !important;
  height: 100% !important;
}

.carousel .slide img {
  height: 75vh; /* change this to whatever you want */
  width: 100%;
}

.carousel .slide .legend {
  font-size: 1em;
}

@media all and (max-width: 1000px) {
  .c-container {
    width: 100%;
    height: 50%;
  }
}
