body {
  margin: 0;
  font-family: 'Noto Sans TC', sans-serif;
}

:root {
  --border-color: #0e56c2;
  --color-transparent: #0e56c2bd;
  --page-color: #ffffff;
  --second-page-color: #d6c6c6;
  --word-color: #4a4a4a;
  --color-dark-transparent: #0d224ea6;
}
