.image-gallery-container {
  position: relative;
  width: 90%;
  height: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.image-gallery-container .container {
  position: relative;
  width: 25%;
  height: 100%;
  margin: 1em;
  overflow: hidden;
}

.image-gallery-container .container .image-box {
  position: relative;
  width: 100%;
  height: 100%;
}

.image-gallery-container .container .image-box img {
  opacity: 1;
  width: 100%;
  height: 100%;
  width: 100%;
  transition: 0.3s ease;
  backface-visibility: hidden;
}

.image-gallery-container .container .image-box .title {
  position: absolute;
  width: 100%;
  height: 10%;
  bottom: 0;
  transition: 0.3s ease;
  background-color: #00080167;
  color: #ffffff;
  text-align: center;
  font-size: 1.2em;
  font-weight: 600;
}

.image-gallery-container .container .information {
  position: absolute;
  transition: 0.3s ease;
  width: 100%;
  height: 100%;
  transform: translate(0%, 0%);
  background-color: #ffffffbb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image-gallery-container .container .information .text {
  position: relative;
  background-color: var(--border-color);
  width: 50%;
  height: 8%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 1.1em;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  border-radius: 10px;
}

.image-gallery-container .container .information p {
  padding: 1.5em;
}

.container:hover img {
  opacity: 0.3;
}

.container:hover .information {
  transform: translate(0%, -100%);
}

.container:hover .image-box .title {
  opacity: 0;
  transform: translate(0%, 100%);
}

@media all and (max-width: 1000px) {
  .image-gallery-container {
    flex-direction: column;
    width: 100%;
    height: 70%;
  }
  .image-gallery-container .container {
    width: 80%;
    height: 23%;
  }
}
