.grid-ele-container {
  position: relative;
  width: 30%;
  height: 100%;
  padding-left: 2em;
  padding-right: 2em;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.grid-ele-container .icon {
  position: relative;
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 10%;
}

.grid-ele-container .icon img {
  position: relative;
  width: 100%;
  height: 100%;
}

.grid-ele-container .content {
  position: relative;
  width: 70%;
  height: 100%;
  text-decoration: none;
}

.grid-ele-container .content .title {
  position: relative;
  width: 100%;
  height: 100%;
  transform: translate(0%, 0%);
  transition: 0.3s ease;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.grid-ele-container .content .title p {
  position: relative;
  width: 85%;
  height: 30%;
  margin: 0px;
  font-size: 1em;
  color: var(--word-color);
}

.grid-ele-container .content .title img {
  position: relative;
  width: 15%;
  height: 100%;
}

.grid-ele-container .content .detail {
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: translate(0%, -30%);
  transition: 0.3s ease;
}

.grid-ele-container .content:hover .title {
  transform: translate(0%, -50%);
}

.grid-ele-container .content:hover .detail {
  opacity: 1;
  transform: translate(0%, -50%);
  font-size: 1em;
  color: var(--word-color);
}

@media all and (max-width: 1000px) {
  .grid-ele-container {
    width: 100%;
    height: 30%;
    padding-left: 0px;
    padding-right: 0px;
  }
}
