.demo-bar-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.demo-bar-container .col {
  position: relative;
  width: 25%;
  height: 100%;
  margin-left: 1%;
  margin-right: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.demo-bar-container .col .icon {
  position: relative;
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.demo-bar-container .col .icon img {
  position: relative;
  height: 65%;
}

.demo-bar-container .col .topic {
  position: relative;
  width: 100%;
  height: 10%;
  text-align: center;
  font-size: 2em;
  font-weight: 600;
  color: var(--border-color);
}

.demo-bar-container .col .content {
  position: relative;
  width: 80%;
  height: 50%;
  text-align: center;
  margin-top: 10%;
  font-size: 1.2em;
}

@media all and (max-width: 1000px) {
  .demo-bar-container {
    flex-direction: column;
  }
  .demo-bar-container .col {
    width: 80%;
    height: 30%;
    margin-left: 0px;
    margin-right: 0px;
  }
  .demo-bar-container .col .topic {
    font-size: 2em;
  }
  .demo-bar-container .col .content {
    width: 90%;
  }
}
