.grid-nav-container {
  position: relative;
  width: 70%;
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.grid-nav-container .row {
  position: relative;
  width: 100%;
  height: 25%;
  padding-top: 3%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media all and (max-width: 1000px) {
  .grid-nav-container {
    height: 70%;
  }
  .grid-nav-container .row {
    flex-direction: column;
  }
}
