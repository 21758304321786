.concept-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.concept-container .bg {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: -1;
}

.concept-container .bg img {
  position: relative;
  width: 100%;
  height: 100%;
}

/* Section 1 */

.concept-container .section-1 {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #00000077;
  justify-content: center;
  align-items: center;
}

.concept-container .section-1 .content {
  position: relative;
  width: 75%;
  height: 20%;
  margin-top: 5%;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  font-size: 4em;
  font-weight: 1000;
}
.concept-container .section-1 .logo-container {
  position: relative;
  width: 10%;
  height: auto;
  justify-content: center;
}

.concept-container .section-1 .explore-icon {
  position: relative;
  width: 5%;
  height: auto;
  justify-content: center;
  padding-top: 5%;
}

.concept-container .section-1 .explore-icon path {
  stroke: white;
  stroke-width: 8px;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.concept-container .section-2 {
  position: relative;
  width: 100%;
  height: 60vh;
  background-color: #ffffff;
}

.concept-container .section-5 {
  position: relative;
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  background-color: var(--color-dark-transparent);
  justify-content: center;
  align-items: center;
}

@media all and (max-width: 1000px) {
  .concept-container .section-1 .content {
    font-size: 2.5em;
    width: 90%;
  }
  .concept-container .section-1 .logo-container {
    width: 40%;
  }
  .concept-container .section-1 .logo-container {
    width: 40%;
  }
  .concept-container .section-2 {
    height: 80vh;
  }
}
