.NavMenuContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.NavMenuContainer .NavList {
  padding: 1em 0.8em;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.NavMenuContainer .NavList .NavLink {
  font-weight: 600;
  height: 35px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.NavMenuContainer .NavList .NavLink a {
  text-decoration: none;
  color: #ffffff;
  font-size: 1.2em;
  transition: all 200ms ease-in-out;
}

.NavMenuContainer .NavList .NavLink:hover a {
  color: rgb(167, 162, 162);
}
