.scale-list-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: solid 0.03em var(--border-color);
  border-bottom: solid 0.03em var(--border-color);
}

.scale-list-container .row {
  position: relative;
  width: 100%;
  height: 18%;
  transition: all 0.2s ease-in-out;
}

.scale-list-container .row .row-inner {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  text-decoration: none;
}

.scale-list-container .row .row-inner .title {
  position: relative;
  width: 88%;
  margin-left: 2%;
  height: 100%;
  color: var(--word-color);
  font-size: 1.5em;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.scale-list-container .row .row-inner .arrow {
  position: relative;
  width: 10%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.scale-list-container .row .row-inner .arrow img {
  height: 50%;
}

.scale-list-container .row:hover {
  transform: scale(0.98);
  box-shadow: 2px 2px 10px #888888;
  border-radius: 15px;
}

@media all and (max-width: 1000px) {
  .scale-list-container .row .row-inner .title {
    font-size: 1em;
  }
}
